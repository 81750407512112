import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation, Trans } from "react-i18next";
import InputMask from "react-input-mask";
import GetSession from "../../composable/GetSession";
import SetSession from "../../composable/SetSession";
import setSession from "../../composable/SetSession";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CompressImage from "../../composable/CompressImage";
import TheLoader from "../../components/TheLoader";
import CustomSelect from "../../components/CustomSelect";

const ProductRegistration = () => {
  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formError, setError] = useState({ status: null });
  const [dealers, setDealers] = useState([]);
  const [firstLoad, setFirstLoad] = useState(false);
  const [products, setProducts] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [promotionsController, setPromotionsController] = useState([]);
  const [countries, setCountries] = useState([]);
  const [serieNummer, setSerieNummer] = useState([]);
  const [factuur, setFactuur] = useState([]);
  const { t } = useTranslation();

  const userData = JSON.parse(localStorage.getItem("userData"));

  axios.defaults.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
    adapter: "jsonAdapter",
  };

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH, OPTIONS",
      "content-type": "application/json",
    },
  };

  const updateSucces = () =>
    toast.success(t("action-registration.sent"), {
      position: "top-center",
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    let formDataArray = new FormData();
    formDataArray.append("article_id", formData.article_id.article_id);
    formDataArray.append("product_amount", 1);
    formDataArray.append("serialnumbers[]", formData.serialnumbers);
    formDataArray.append("dealer_id", formData.dealer_id);
    formDataArray.append("purchase_date", formData.purchase_date);
    formDataArray.append("promotion_id", formData.promotion_id);
    formDataArray.append("serialnumber_image[]", serieNummer.url);
    formDataArray.append("purchase_image", factuur.url);
    formDataArray.append("terms_and_conditions", formData.terms_and_conditions);
    formDataArray.append("gift", formData.gift);
    formDataArray.append("zipcode", formData.zipcode);
    formDataArray.append("housenumber", formData.housenumber);
    formDataArray.append("streetname", formData.streetname);
    formDataArray.append("city", formData.city);
    formDataArray.append("country_id", formData.country_id);

    axios
      .post(`${process.env.REACT_APP_API_URL}registration`, formDataArray, axiosConfig)
      .then(function (response) {
        localStorage.setItem("userData", JSON.stringify(response.data.user));
        setLoading(false);
        updateSucces();
      })
      .catch(function (error) {
        setLoading(false);
        setError(error.response.data.errors);
      });
    setLoading(false);
  };

  useEffect(() => {
    if (firstLoad === false) {
      setLoading(true);

      if (GetSession("products") !== null) {
        setProducts(GetSession("products"));
        setLoading(false);
      } else {
        axios
          .get(`${process.env.REACT_APP_API_URL}article?warranty-available=true`)
          .then((response) => {
            setProducts(response.data.data);
            SetSession("products", response.data.data);
            setLoading(false);
          })
          .catch((err) => {
            throw new Error(err);
          });
      }

      if (GetSession("dealers") !== null) {
        setDealers(GetSession("dealers"));
      } else {
        axios
          .get(`${process.env.REACT_APP_API_URL}dealer`)
          .then((response) => {
            setDealers(response.data.data);
            SetSession("dealers", response.data.data);
          })
          .catch((err) => {
            throw new Error(err);
          });
      }

      if (GetSession("countries") !== null) {
        setCountries(GetSession("countries"));
      } else {
        axios
          .get(`${process.env.REACT_APP_API_URL}country`)
          .then((response) => {
            setCountries(response.data.data);
            setSession("countries", response.data.data, 1440);
          })
          .catch((err) => {
            throw new Error(err);
          });
      }

      setFormData({
        ...formData,
        zipcode: userData.address.postal,
        housenumber: userData.address.housenumber,
        streetname: userData.address.street,
        city: userData.address.city,
        country_id: userData.address.country_id,
      });

      setFirstLoad(true);
    }
  }, [
    firstLoad,
    formData,
    userData.address.city,
    userData.address.country_id,
    userData.address.housenumber,
    userData.address.postal,
    userData.address.street,
  ]);

  useEffect(() => {
    // TODO: Test when api is enabled
    const { housenumber, zipcode } = formData;

    if (!housenumber || housenumber.length < 1) return;
    if (!zipcode || zipcode.length < 6) return;

    const item = {
      zipcode: zipcode,
      housenumber: housenumber,
    };

    const fetchAddressData = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}zipcode`, item);
        // Update formData with city and street from the response
        setFormData((prevData) => ({
          ...prevData,
          city: response.data.city,
          street: response.data.street,
        }));
      } catch (error) {
        console.error("Error fetching address data:", error);
        setError(error.response.data.errors);
      }
    };

    fetchAddressData();
  }, [formData.housenumber, formData.postal]);

  const handleTerms = (e) => {
    e.target.checked
      ? setFormData({ ...formData, terms_and_conditions: 1 })
      : setFormData({ ...formData, terms_and_conditions: 0 });
  };

  const handleDealer = ({ value }) => {
    setFormData({ ...formData, dealer_id: value });
  };

  const handleProduct = ({ value }) => {
    setFormData({ ...formData, article_id: JSON.parse(value) });
  };

  const handleCountry = ({ value }) => {
    setFormData({ ...formData, country_id: value });
  };

  useEffect(() => {
    if (formData.article_id) {
      if (formData.purchase_date) {
        if (formData.dealer_id) {
          if (promotionsController !== formData.article_id + formData.purchase_date) {
            handlePromotions();
          }
        }
      }
    }
  }, [formData.article_id, formData.purchase_date, formData.dealer_id]);

  const handlePromotions = () => {
    if (formData.article_id === undefined || formData.purchase_date === null) {
      return;
    }
    setLoading(true);
    try {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}promotion/${formData.dealer_id}/${formData.article_id.article_id}/${formData.purchase_date}`,
        )
        .then((response) => {
          setPromotions(response.data.data);
          setPromotionsController(formData.article_id + formData.purchase_date);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      throw new Error(error);
    }
    return null;
  };

  const handleGetGift = (promotion) => {
    setFormData({ ...formData, promotion_id: promotion.id });

    axios
      .get(`${process.env.REACT_APP_API_URL}gift/${promotion.id}/${formData.article_id.article_id}`)
      .then((response) => {
        setFormData({ ...formData, gift: response.data[0].id });
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  const uploadImage = async (file, stateName) => {
    if (file.size <= 5000000) {
      const fileType = file.type.split("/")[0];

      if (fileType === "image") {
        file = await CompressImage(file);
      }

      const image = new FormData();
      image.append("file", file);
      axios
        .post(`${process.env.REACT_APP_API_URL}file/upload`, image, {
          headers: {
            ...axiosConfig.headers,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          if (stateName === "serieNummer") {
            setSerieNummer(response.data);
          } else if (stateName === "factuur") {
            setFactuur(response.data);
          }
        })
        .catch(function (error) {
          setError(error.response.data.errors);
        });
    } else {
      if (stateName === "serieNummer") {
        setError({
          serialnumber_image: t("error.file-too-large"),
        });
      } else if (stateName === "factuur") {
        setError({
          purchase_image: t("error.file-too-large"),
        });
      }
    }
  };

  return (
    <>
      <TheLoader isLoading={loading} />

      <Layout Title={t("action-registration.title")}>
        <div className="flex w-full items-center justify-between">
          <span className="font-robotoslab font-bold text-primary dark:text-primary-light">
            {t("action-registration.add-product")}
          </span>
          <Link to="./registraties">
            <button className="btn btn--primary">
              {t("action-registration.registered-actions")}
              <FontAwesomeIcon icon="fa-regular fa-arrow-right-long" />
            </button>
          </Link>
        </div>

        <form onSubmit={handleSubmit} className="mt-8 grid grid-cols-2 gap-x-4 gap-y-6">
          <div className="col-span-full">
            <p className="pb-3 text-sm">{t("general.product")}</p>
            <CustomSelect
              id="article_id"
              placeholder={t("platform-help.select-product")}
              options={products.map((product) => ({
                value: JSON.stringify(product),
                label: `${product.article_number} - ${product.name}`,
              }))}
              onChange={handleProduct}
            />
            {formError.article_id && <span className="mt-2 text-red-500">{formError.article_id}</span>}
          </div>
          <div className="col-span-full md:col-span-1">
            <p className="pb-3 text-sm">{t("general.date-of-purchase")}</p>
            <input
              id="purchase_date"
              type="date"
              pattern="\d{4}-\d{2}-\d{2}"
              max={new Date().toISOString().split("T")[0]}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  purchase_date: e.target.value,
                })
              }
              className="dark:glass h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md"
            />
          </div>
          {formError.purchase_date && <span className="mt-2 text-red-500">{formError.purchase_date}</span>}

          <div className="col-span-full md:col-span-1">
            <p className="pb-3 text-sm">{t("general.dealer")}</p>
            <CustomSelect
              id="dealer_id"
              placeholder={t("general.select-dealer")}
              options={dealers.map((dealer) => ({
                value: dealer.id,
                label: dealer.name,
              }))}
              onChange={handleDealer}
            />
          </div>
          {formError.dealer && <span className="mt-2 text-red-500">{formError.dealer}</span>}

          {promotions.length ? (
            <div className="col-span-full">
              <div className="grid grid-cols-2 gap-x-4 gap-y-6">
                <div className={`col-span-full ${formData.article_id && "md:col-span-1"}`}>
                  <div>
                    <p className="pb-3 text-sm">{t("general.serialnumber")}</p>
                    <InputMask
                      mask="999999999"
                      id="serialnumbers"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          serialnumbers: e.target.value,
                        })
                      }
                      placeholder={t("general.serialnumber")}
                      className="dark:glass h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md"
                      required
                    />
                  </div>
                  {formError.serialnumbers && <span className="mt-2 text-red-500">{formError.serialnumbers}</span>}
                </div>

                {formData.article_id && (
                  <div className="col-span-full md:col-span-1">
                    <p className="pb-3 text-sm text-black/50 dark:text-white">{t("general.serialnumber-example")}</p>
                    <div className="card dark:glass relative mx-auto w-full rounded-xl bg-white p-4 shadow-md">
                      <p className="pb-3 text-sm text-black/50 dark:text-white">
                        {t("general.serialnumber-placeholder")}
                      </p>
                      <div className="flex w-full justify-center">
                        <img
                          src={formData.article_id.serialnumber_photo}
                          alt="productnummer voorbeeld"
                          className="max-h-[200px] rounded-xl"
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-span-full">
                  <p className="pb-3 text-sm">{t("action-registration.registering-for")}:</p>
                  <CustomSelect
                    id="promotion_id"
                    placeholder={t("general.select-promotion")}
                    options={promotions.map((promotion) => ({
                      value: promotion.id,
                      label: promotion.name,
                    }))}
                    onChange={({ value }) => handleGetGift(promotions.find((p) => p.id === parseInt(value)))}
                    noOptionsMessage={t("action-registration.no-promotions-found")}
                  />
                  {formError.promotion_id && <span className="mt-2 text-red-500">{formError.promotion_id}</span>}
                </div>

                <div className="col-span-full">
                  <input
                    type="text"
                    disabled
                    placeholder={t("action-registration.select-product-and-date")}
                    className="dark:glass h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md"
                  />
                  {formError.gift && <span className="mt-2 text-red-500">{formError.gift}</span>}
                </div>

                <div className="col-span-full md:col-span-1">
                  <div>
                    <label>
                      <input
                        type="file"
                        hidden
                        id="serialnumber_photo"
                        onChange={(e) => uploadImage(e.target.files[0], "serieNummer")}
                      />
                      <p className="pb-3 text-sm">{t("general.serialnumber-photo")}</p>
                      <div className="btn btn--secondary justify-start">
                        <FontAwesomeIcon icon="fa-regular fa-paperclip" /> {t("general.serialnumber-photo-upload")}
                      </div>
                    </label>
                  </div>
                  {formError.serialnumber_image && (
                    <span className="mt-2 text-red-500">{formError.serialnumber_image}</span>
                  )}

                  {serieNummer.url && (
                    <img
                      src={serieNummer.url + "?token=" + localStorage.getItem("userAuth")}
                      className="mt-3 w-1/2 object-cover"
                      alt="serienummer foto"
                    />
                  )}
                </div>

                <div className="col-span-full md:col-span-1">
                  <div>
                    <label>
                      <input
                        type="file"
                        hidden
                        id="purchase_image"
                        onChange={(e) => uploadImage(e.target.files[0], "factuur")}
                      />
                      <p className="pb-3 text-sm">{t("general.invoice-photo")}</p>
                      <div className="btn btn--secondary justify-start">
                        <FontAwesomeIcon icon="fa-regular fa-paperclip" /> {t("general.invoice-upload")}
                      </div>
                    </label>
                  </div>
                  {formError.purchase_image && <span className="mt-2 text-red-500">{formError.purchase_image}</span>}

                  {factuur.url && (
                    <img
                      src={factuur.url + "?token=" + localStorage.getItem("userAuth")}
                      className="mt-3 w-1/2 object-cover"
                      alt="factuur foto"
                    />
                  )}
                </div>

                <div className="col-span-full">
                  <hr className="border-line" />
                </div>

                <div className="col-span-full">
                  <p className="pb-3 text-sm">{t("general.country")}</p>
                  <CustomSelect
                    id="country_id"
                    placeholder={t("general.select-country")}
                    options={countries.map((country) => ({
                      value: country.id,
                      label: country.name,
                    }))}
                    onChange={handleCountry}
                  />
                </div>

                <div className="col-span-full md:col-span-1">
                  <p className="pb-3 text-sm">{t("general.zip-code")}</p>
                  <input
                    id="zipcode"
                    value={formData.zipcode}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        zipcode: e.target.value,
                      })
                    }
                    className="dark:glass h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md"
                    required
                  />
                </div>
                <div className="col-span-full md:col-span-1">
                  <p className="pb-3 text-sm">{t("general.housenumber")}</p>
                  <input
                    id="housenumber"
                    value={formData.housenumber}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        housenumber: e.target.value,
                      })
                    }
                    className="dark:glass h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md"
                    required
                  />
                </div>
                <div className="col-span-full md:col-span-1">
                  <p className="pb-3 text-sm">{t("general.streetname")}</p>
                  <input
                    id="streetname"
                    value={formData.streetname}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        streetname: e.target.value,
                      })
                    }
                    className="dark:glass h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md"
                    required
                  />
                </div>
                <div className="col-span-full md:col-span-1">
                  <p className="pb-3 text-sm">{t("general.residence")}</p>
                  <input
                    id="city"
                    value={formData.city}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        city: e.target.value,
                      })
                    }
                    className="dark:glass h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md"
                    required
                  />
                </div>

                <div className="col-span-full">
                  <input type="checkbox" id="privacy_statement" required onChange={(e) => handleTerms(e)} />
                  <span className="ml-2 text-sm">
                    <Trans
                      i18nKey="general.privacy-policy-consent"
                      components={{
                        link1: (
                          <a
                            href={process.env.REACT_APP_PRIVACY_POLICY}
                            className="link text-primary dark:text-primary-light"
                          />
                        ),
                      }}
                    />
                  </span>
                </div>

                <div className="col-span-full">
                  <button type="submit" className="btn btn--primary w-full" value="registreren">
                    {t("general.send-registration")}
                    <FontAwesomeIcon icon="fa-solid fa-arrow-right-long" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <p className="dark:glass/20 col-span-full border border-line/20 bg-white/50 px-4 py-3">
              {formData.article_id && formData.purchase_date && formData.dealer_id
                ? t("error.no-running-action")
                : t("action-registration.fill-data")}
            </p>
          )}
        </form>
      </Layout>
    </>
  );
};

export default ProductRegistration;
