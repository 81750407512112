import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iOS from "../../composable/ios";

const TopNav = ({ Title }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div
      className={`bg-nav dark:bg-nav-dark fixed left-0 top-0 z-50 grid w-full grid-cols-12 ${iOS() ? "pt-12" : "pt-4"}`}
    >
      <div className="default-cols flex w-full items-center justify-between px-4 py-2">
        <div className="container flex lg:px-0">
          <button onClick={handleGoBack} className="flex w-20 items-center justify-start py-1">
            <FontAwesomeIcon icon="fa-regular fa-chevron-left" />
          </button>
          <div className="flex w-full items-center justify-center">
            <span className="w-full text-center font-robotoslab">{Title}</span>
          </div>
          <div className="flex w-20 items-center justify-end gap-x-4">
            <Link to="../notifications" className="w-6 py-1 text-center">
              <FontAwesomeIcon icon="fa-regular fa-bell" />
            </Link>
            <Link to="../profiel" className="w-6 py-1 text-center">
              <FontAwesomeIcon icon="fa-regular fa-user" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNav;
