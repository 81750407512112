import React from "react";
import Layout from "../../components/Layout";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDebouncedCallback } from "use-debounce";
import formatDate from "../../composable/FormatDate";
import TheLoader from "../../components/TheLoader";

const HistoryWarrantyRegistration = () => {
  const [search, setSearch] = useState("");
  const [registrations, SetRegistations] = useState([]);
  const [firstLoad, setFirstLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const debouncedSearch = useDebouncedCallback((value) => setSearch(value), 700);

  // set axios headers
  axios.defaults.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
  };

  // On firstLoad
  useEffect(() => {
    if (firstLoad === false) {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}warranty-registration`)
        .then((res) => {
          SetRegistations(res.data.data);
          setLoading(false);
        })
        .catch(() => {
          toast(t("error.loading-registrations"), {
            type: "error",

            position: "top-center",
          });
          setLoading(false);
        });

      setFirstLoad(true);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}warranty-registration?search=` + search)
      .then((response) => {
        SetRegistations(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        toast.error(t("error.something-went-wrong"), {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
        });
        return error;
      });
  }, [search]);

  return (
    <>
      {/* Loader */}
      <TheLoader isLoading={loading} />

      <Layout Title={t("warranty-registration.registered-warranties")}>
        <div className="grid grid-cols-4">
          <div className="relative col-span-full flex justify-between gap-4">
            <div className="relative w-full">
              <div className="absolute left-4 top-2">
                <FontAwesomeIcon icon="fa-regular fa-search" />
              </div>
              <input
                className="h-10 w-full rounded-full py-2 pl-11 pr-4 text-sm shadow-md dark:bg-secondary-dark"
                placeholder={t("general.search")}
                type="text"
                id="search"
                onChange={(e) => debouncedSearch(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="mb-6 mt-8 grid grid-cols-1 gap-x-4 gap-y-6 lg:grid-cols-2">
          {registrations.map((registration) => {
            return (
              <div key={registration.id}>
                <Link to={`./${registration.serialnumber}`} state={registration}>
                  <div className="dark:glass grid h-full grid-cols-3 rounded-xl bg-white pr-6 shadow-md">
                    <div className="col-span-1">
                      <div className="flex h-full items-center justify-center rounded-l-xl bg-white p-4">
                        {registration.article.image && (
                          <img
                            src={registration.article.image}
                            alt={registration.serialnumber}
                            className="h-full max-h-20 w-full object-contain"
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-span-2 flex flex-col gap-1 py-6 pl-4">
                      <span className="font-robotoslab font-bold text-primary dark:text-primary-light">
                        {registration.article.name}
                      </span>

                      <div className="mt-2 flex justify-between text-sm">
                        <strong>{t("general.registration")}: </strong>
                        <span className="text-right">{formatDate(registration.created_at)}</span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <strong>{t("general.serialnumber")}: </strong>
                        <span className="text-right">{registration.serialnumber}</span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
          {registrations.length === 0 && !loading && (
            <div className="mt-5 text-center">{t("warranty-registration.no-registrations-found")}</div>
          )}
        </div>
      </Layout>
    </>
  );
};

export default HistoryWarrantyRegistration;
