import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Button from "../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ThemeSwitcher from "../components/ThemeSwitcher";

const More = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const { t } = useTranslation();

  let navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("userAuth")) {
      setAuthenticated(true);
    }
  }, []);

  const currentYear = new Date().getFullYear();

  const handleLogout = (e) => {
    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_API_URL}logout`)
      .then(function (response) {
        localStorage.clear();
        sessionStorage.clear();
        navigate("/", { replace: true });
        return response;
      })
      .catch(function () {
        localStorage.clear();
        sessionStorage.clear();
        navigate("/", { replace: true });
      });
  };

  return (
    <>
      <Layout Title="Meer opties">
        <span className="font-robotoslab font-bold text-primary dark:text-primary-light">
          {t("general.possibilities")}
        </span>
        <div className="mb-6 mt-4 grid grid-cols-1 gap-y-4 lg:grid-cols-2 lg:gap-x-4">
          {authenticated === true ? (
            <Button to="/discover" icon="compass" text={t("discover.title")} arrow />
          ) : (
            <div
              className="flex h-[46px] cursor-not-allowed items-center justify-between rounded-xl bg-button-inactive
                px-3 font-robotoslab font-bold text-black/50 shadow-md dark:bg-button-inactive-dark dark:text-white"
            >
              <div className="flex items-center justify-start gap-2">
                <div className="w-5">
                  <FontAwesomeIcon icon="fa-regular fa-compass" />
                </div>
                Discover
              </div>
              <FontAwesomeIcon icon="fa-regular fa-arrow-right-long" className="text-black/50 dark:text-white" />
            </div>
          )}
          <Button to="/platform" icon="messages-question" text={t("platform-help.title")} arrow />
          {authenticated === true ? (
            <Button to="/notifications" icon="bell" text={t("general.notifications")} arrow />
          ) : (
            <div
              className="flex h-[46px] cursor-not-allowed items-center justify-between rounded-xl bg-button-inactive
                px-3 font-robotoslab font-bold text-black/50 shadow-md dark:bg-button-inactive-dark dark:text-white"
            >
              <div className="flex items-center justify-start gap-2">
                <div className="w-5">
                  <FontAwesomeIcon icon="fa-regular fa-bell" />
                </div>
                {t("general.notifications")}
              </div>
              <FontAwesomeIcon icon="fa-regular fa-arrow-right-long" className="text-black/50 dark:text-white" />
            </div>
          )}
        </div>
        <ThemeSwitcher />
        <div className="mb-6 mt-4 flex flex-col gap-y-3">
          <span className="font-robotoslab font-bold text-primary dark:text-primary-light">{t("general.options")}</span>
          {authenticated === true ? (
            <Button to="/profiel" icon="user" text={t("profile.edit-profile")} arrow />
          ) : (
            <div
              className="flex h-[46px] cursor-not-allowed items-center justify-between rounded-xl bg-button-inactive
                px-3 font-robotoslab font-bold text-black/50 shadow-md dark:bg-button-inactive-dark dark:text-white"
            >
              <div className="flex items-center justify-start gap-2">
                <div className="w-5">
                  <FontAwesomeIcon icon="fa-regular fa-user" />
                </div>
                {t("profile.edit-profile")}
              </div>
              <FontAwesomeIcon icon="fa-regular fa-arrow-right-long" className="text-black/50 dark:text-white" />
            </div>
          )}
          {authenticated === true ? (
            <button onClick={handleLogout} className="btn btn--primary justify-start">
              <FontAwesomeIcon icon="fa-regular fa-right-from-bracket" />
              <span>{t("general.logout")}</span>
            </button>
          ) : (
            <Button to="/login" icon="right-from-bracket" text={t("general.login")} primary arrow />
          )}
        </div>
        <p>Makita Nederland &copy; {currentYear}</p>
        {/* eslint-disable-next-line no-undef */}
        <p className="text-primary dark:text-primary-light">
          {t("general.version")} {process.env.REACT_APP_VERSION}
        </p>
      </Layout>
    </>
  );
};

export default More;
