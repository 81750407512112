import React from "react";
import Layout from "../../components/Layout";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import formatDate from "../../composable/FormatDate";
import StatusBar from "../../components/StatusBar";

const DetailWarrantyRegistration = () => {
  const location = useLocation();
  const data = location.state;
  const { t } = useTranslation();
  console.log(data);

  // Calculate total points

  function getTotalPoints() {
    let totalPoints = 0;
    data.items.map((item) => {
      totalPoints += item.points;
      return null;
    });
    return totalPoints;
  }

  return (
    <Layout Title={t("general.order")}>
      <div className="relative z-10 flex flex-col gap-y-5">
        <StatusBar statuses={data.statuses} hideText />
      </div>
      <div className="mb-5 mt-3 flex justify-center">
        <span className="text-center">{data.statuses[0].name}</span>
      </div>

      <hr className="my-6 border-line" />

      <div className="col-span-12 flex flex-col gap-3">
        <div className="flex justify-between text-sm">
          <strong className="flex-1">{t("general.reference")}: </strong>
          <span className="flex-1 text-right">
            {t("general.order")} #{data.external_id}
          </span>
        </div>
        <div className="flex justify-between text-sm">
          <strong className="flex-1">{t("gift-shop.ordered-on")}: </strong>
          <span className="flex-1 text-right">{formatDate(data.created_at)}</span>
        </div>
        <div className="flex justify-between text-sm">
          <strong className="flex-1">{t("general.points")}: </strong>
          <span className="flex-1 text-right">{getTotalPoints()}</span>
        </div>
        <div className="flex justify-between text-sm">
          <p>
            <strong>{t("general.post-address")}:</strong>
          </p>
          <p className="text-right">
            <span>{`${data.address.streetname} ${data.address.housenumber}`}</span>
            <br />
            {`${data.address.zipcode} ${data.address.city}`}
          </p>
        </div>
      </div>

      <hr className="my-6 border-line" />

      <div className="flex flex-col gap-y-6">
        {data.items.map((item) => {
          return (
            <div className="col-span-12 grid grid-cols-12" key={item.article.id}>
              <div className="col-span-4 p-3 dark:bg-white">
                <img
                  src={data.items[0].article.images[0]}
                  alt={data.items[0].article.name}
                  className="h-full max-h-20 w-full object-contain"
                />
              </div>
              <div className="col-span-8 flex flex-col gap-y-3 pl-4">
                <p className="font-robotoslab font-bold text-primary dark:text-primary-light">{item.article.name}</p>
                <div className="flex justify-between text-sm">
                  <strong className="flex-1 font-bold">{t("gift-shop.amount")}: </strong>
                  <span className="flex-1 text-right">{item.amount}</span>
                </div>
                <div className="flex justify-between text-sm">
                  <strong className="flex-1 font-bold">{t("general.points")}: </strong>
                  <span className="flex-1 text-right">{item.points}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Layout>
  );
};

export default DetailWarrantyRegistration;
