import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

const InstagramCard = ({ post }) => {
  const { t } = useTranslation();

  return (
    <div className="dark:glass overflow-hidden rounded-lg bg-white shadow-md">
      <main className="p-6">
        <div className="mb-6 flex items-center">
          <FontAwesomeIcon icon="fa-brands fa-instagram" className="text-primary dark:text-primary-light" />
          <span className="ml-3 font-robotoslab font-bold text-primary dark:text-primary-light">
            {t("general.instagram")}
          </span>
        </div>
        {post.type === "image" && <img src={post.url} className="mb-6 w-full rounded-xl object-cover" alt="" />}
        {post.type === "carousel" && (
          <Swiper pagination={true} modules={[Pagination]} className="instaSlider mb-6">
            {post.children.map((item) => (
              <SwiperSlide key={item.id}>
                <img src={item.url} className="w-full rounded-xl object-cover" alt="" />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        {post.type === "video" && (
          // TODO: maybe change to show the video
          <img src={post.thumbnail_url} className="mb-6 w-full rounded-xl object-cover" alt="" />
        )}
        <p className="line-clamp-4 text-sm">{post.caption}</p>
      </main>
    </div>
  );
};
export default InstagramCard;
