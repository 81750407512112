import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import TheLoader from "../../components/TheLoader";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CapacitorController from "../../components/CapacitorController";
import iOS from "../../composable/ios";
import TopNavGuest from "../../components/navbar/TopNavGuest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../components/Button";
import GuestNav from "../../components/navbar/GuestNav";

const session_url = `${process.env.REACT_APP_API_URL}login`;
const validate_send_url = `${process.env.REACT_APP_API_URL}verification-code/send`;
const validate_url = `${process.env.REACT_APP_API_URL}verification-code/verify`;

const Login = () => {
  // Variable
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    code: "",
  });
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [FormError, setError] = useState({ status: null, data: { errors: { email: "", password: "" } } });
  const [validate, setValidate] = useState(false);
  const [verifyMessage, setVerifyMessage] = useState("");
  const { t } = useTranslation();
  let navigate = useNavigate();

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const handleSendValidation = async () => {
    if (formData.email !== "") {
      axios
        .post(validate_send_url, {
          email: formData.email,
        })
        .then((response) => {
          setVerifyMessage(response.data.message);
          setLoading(false);
          setValidate(true);
        })
        .catch(function (error) {
          setError(error.response);
          setLoading(false);
        });
    }
  };

  // Functions
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.email !== "" && formData.password !== "") {
      setLoading(true);

      axios
        .post(session_url, {
          email: formData.email,
          password: formData.password,
        })
        .then(function (response) {
          setVerifyMessage(response.data.message);
          setLoading(false);
          setValidate(true);
        })
        .catch(function (error) {
          setError(error.response);
          setLoading(false);
        });
    }
  };

  const validateUser = async (e) => {
    e.preventDefault();

    if (formData.email !== "" && formData.code !== "") {
      setLoading(true);

      axios
        .post(validate_url, {
          email: formData.email,
          code: formData.code,
        })
        .then(async function (response) {
          localStorage.setItem("userAuth", response.data.token);
          await handleGetUser();

          setLoading(false);
        })
        .catch(function (error) {
          setError(error.response);
          setLoading(false);
        });
    }
  };

  const handleGetUser = async () => {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
      adapter: "jsonAdapter",
    };
    axios.get(`${process.env.REACT_APP_API_URL}user/@me`).then((res) => {
      localStorage.setItem("userData", JSON.stringify(res.data.data));
      if (localStorage.getItem("userAuth") !== undefined) {
        navigate("/home");
      } else {
        setTimeout(() => {
          navigate("/home");
        }, 2000);
      }
    });
  };

  return (
    <div className={`grid grid-cols-12 ${iOS() ? "mt-6" : "mt-12"}`}>
      <TheLoader isLoading={loading} />
      <TopNavGuest Title={t("general.login")} />
      <div className="content default-cols container mb-[120px] px-4 md:mb-0">
        <div className="grid grid-cols-1 gap-y-4">
          {!validate && (
            <>
              <h3 className="col-span-full mt-8 font-robotoslab font-bold text-primary dark:text-primary-light">
                {t("general.login")}
              </h3>

              <form onSubmit={handleSubmit}>
                <p className="pb-3 text-sm">{t("general.email-address")}</p>

                <label htmlFor="email">
                  <input
                    type="email"
                    id="email"
                    placeholder={t("general.email-address")}
                    className="h-10 w-full rounded-full px-4 py-2 text-sm shadow-md dark:bg-secondary-dark"
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  />
                </label>
                {FormError.data.errors.email && <p className="mt-1 text-makita-red">{FormError.data.errors.email}</p>}

                <p className="pb-3 pt-6 text-sm">{t("general.password")}</p>
                <label htmlFor="password" className="relative">
                  <input
                    type={passwordType}
                    id="password"
                    placeholder={t("general.password")}
                    className="h-10 w-full rounded-full px-4 py-2 text-sm shadow-md dark:bg-secondary-dark"
                    value={formData.password}
                    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                  />

                  <div className="absolute inset-y-0 right-4">
                    <button type="button" onClick={togglePassword}>
                      <FontAwesomeIcon
                        icon={`fa-regular ${passwordType === "password" ? "fa-eye" : "fa-eye-slash"}`}
                        className="text-primary"
                      />
                    </button>
                  </div>
                </label>

                {FormError.data.errors.password && (
                  <p className="mt-1 text-makita-red">{FormError.data.errors.password}</p>
                )}

                <div className="mt-6 flex flex-col gap-x-4 gap-y-6 lg:flex-row lg:justify-between">
                  <div className="w-full">
                    <button
                      disabled={!formData.email || !formData.password}
                      className="btn btn--primary w-full disabled:cursor-not-allowed disabled:opacity-60 disabled:hover:bg-primary"
                    >
                      {t("general.login")}
                      <FontAwesomeIcon icon="fa-regular fa-arrow-right-long" />
                    </button>
                  </div>

                  <Link to="wachtwoord-vergeten" className="flex w-full items-center justify-end">
                    <p className="animate font-robotoslab text-primary hover:opacity-50 dark:text-primary-light">
                      {t("general.forgot-password-link")}
                    </p>
                  </Link>
                </div>
              </form>

              <div className="flex flex-col gap-y-4">
                <hr className="my-6 border-line" />
                <h3 className="col-span-full font-robotoslab font-bold text-primary">{t("general.no-member-yet")}</h3>
                <p>{t("general.become-member")}</p>
                <Button to="../registreren" text={t("general.register")} arrow />
              </div>
            </>
          )}
          {validate && (
            <>
              <h3 className="col-span-full mt-8 font-robotoslab font-bold text-primary">
                {t("general.validation-code")}
              </h3>

              {verifyMessage !== "" &&
                (iOS() ? (
                  <a href="message://">{verifyMessage}</a>
                ) : (
                  <div className="flex-col gap-y-4">
                    <div>{verifyMessage}</div>
                    <div className="mt-4 flex w-full flex-wrap gap-x-4">
                      <a
                        href="https://mail.google.com/mail/u/"
                        className="animate text-primary hover:opacity-50 dark:text-primary-light"
                      >
                        Gmail
                      </a>
                      <a
                        href="https://outlook.live.com/mail/inbox"
                        className="animate text-primary hover:opacity-50 dark:text-primary-light"
                      >
                        Outlook
                      </a>
                      <a
                        href="https://www.icloud.com/mail"
                        className="animate text-primary hover:opacity-50 dark:text-primary-light"
                      >
                        iCloud
                      </a>
                    </div>
                  </div>
                ))}

              <form onSubmit={validateUser}>
                <p className="py-3 text-sm">{t("general.email-address")}</p>

                <label htmlFor="email">
                  <input
                    type="email"
                    id="email"
                    placeholder={t("general.email-address")}
                    className="h-10 w-full rounded-full bg-button-inactive px-4 py-2 text-sm shadow-md dark:bg-button-inactive-dark"
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    disabled
                  />
                </label>

                <p className="pb-3 pt-6 text-sm">{t("general.validation-code")}</p>

                <label htmlFor="code">
                  <input
                    type="text"
                    id="code"
                    placeholder={t("general.validation-code")}
                    className="h-10 w-full rounded-full bg-white px-4 py-2 text-sm shadow-md dark:bg-secondary-dark"
                    value={formData.code}
                    onChange={(e) => setFormData({ ...formData, code: e.target.value })}
                  />
                </label>
                {FormError.status === 401 && <p className="mt-1 text-makita-red">{FormError.data.message}</p>}
                <div className="mt-6 flex flex-col gap-y-4 lg:flex-row lg:gap-x-4">
                  <button className="btn btn--primary w-full">
                    {t("general.login")}
                    <FontAwesomeIcon icon="fa-regular fa-arrow-right-long" />
                  </button>
                  <div onClick={() => handleSendValidation()} className="btn btn--secondary w-full">
                    {t("general.resend-validation")}
                  </div>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
      <GuestNav />
      <CapacitorController />
    </div>
  );
};

export default Login;
