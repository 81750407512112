import React from "react";
import GuestNav from "../components/navbar/GuestNav";
import PageCardGuest from "../components/cards/PageCardGuest";
import { gsap } from "gsap";
import { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LandingspageNavbar from "../components/navbar/LandingpageNavbar";
import { useTranslation } from "react-i18next";
import CapacitorController from "../components/CapacitorController";
import iOS from "../composable/ios";
import Button from "../components/Button";

// IMG Imports
import Platform from "../img/platform.webp";
import Cadeauwinkel from "../img/cadeauwinkel.webp";
import Actieregistratie from "../img/Actie.webp";
import Garantieregistratie from "../img/Garantie.webp";
import Discover from "../img/Discover.webp";
import Logo from "../img/logos/Makita_Logo_Transparant.svg";

const StartUp = () => {
  const startup = useRef();
  const q = gsap.utils.selector(startup);
  const tl = useRef();
  let Navigation = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    if (sessionStorage.getItem("appLoaded") === null) {
      tl.current = gsap
        .timeline()
        .to(
          q("#animation-img"),
          {
            scale: 150,
            duration: 1.5,
            ease: "sine.in",
          },
          "+=0.5",
        )
        .to(q("div"), {
          zIndex: -99,
          opacity: 0,
        });
      sessionStorage.setItem("appLoaded", true);
    }
    if (localStorage.getItem("userData")) {
      Navigation("../home");
    }
  }, [Navigation, q]);

  return (
    <>
      <div ref={startup}>
        <div
          className={`fixed z-[9999] flex h-full w-full items-center justify-center bg-makita-red p-20 md:p-40 ${
            sessionStorage.getItem("appLoaded") ? "hidden" : ""
          }`}
        >
          <img src={Logo} className="w-full" id="animation-img" alt="logo" />
        </div>
      </div>

      <div>
        <div className="z-[9999]">
          <LandingspageNavbar />
        </div>

        <div className={`grid grid-cols-12 px-4 pb-28 pt-36 ${iOS() ? "md:pt-40" : "md:pt-48"}`}>
          <div className="default-cols flex flex-col gap-y-6 lg:gap-y-10">
            <div className="flex flex-col gap-y-6">
              <h3 className="font-robotoslab text-2xl font-semibold lg:text-3xl">{t("general.become-member")}</h3>
              <div className="flex flex-col gap-y-4 md:w-full md:flex-row md:justify-between md:gap-x-4">
                <Button to="/login" icon="key" text={t("general.login")} primary arrow wfull />
                <Button to="/registreren" icon="user-plus" text={t("general.register")} arrow wfull />
              </div>
            </div>
            <hr className="border-line" />
            <div className="grid grid-cols-2 gap-4">
              <PageCardGuest
                Title={t("platform-help.title")}
                IMG={Platform}
                Alt={t("platform-help.page-card-alt")}
                PageLink="/platform"
              />
              <PageCardGuest
                Title={t("gift-shop.title")}
                IMG={Cadeauwinkel}
                Alt={t("gift-shop.page-card-alt")}
                PageLink="/cadeauwinkel"
              />
            </div>
            <div className="flex flex-col gap-y-6">
              <span className="pt-2 font-robotoslab text-xl font-semibold lg:text-2xl">{t("general.why-login")}</span>
              <div className="w-full overflow-x-scroll pb-4">
                <div className="slides flex gap-4 transition">
                  <div className="h-full min-w-[90%] transition-transform duration-500 md:min-w-[60%] lg:w-full lg:min-w-0">
                    <PageCardGuest
                      Title={t("action-registration.title")}
                      IMG={Actieregistratie}
                      Alt={t("action-registration.page-card-alt")}
                      PageLink="/login"
                      wide
                    />
                  </div>
                  <div className="h-full min-w-[90%] transition-transform duration-500 md:min-w-[60%] lg:w-full lg:min-w-0">
                    <PageCardGuest
                      Title={t("warranty-registration.title")}
                      IMG={Garantieregistratie}
                      Alt={t("warranty-registration.page-card-alt")}
                      PageLink="/login"
                      wide
                    />
                  </div>
                  <div className="h-full min-w-[90%] transition-transform duration-500 md:min-w-[60%] lg:w-full lg:min-w-0">
                    <PageCardGuest
                      Title={t("discover.title")}
                      IMG={Discover}
                      Alt={t("discover.page-card-alt")}
                      PageLink="/login"
                      wide
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GuestNav />
        <CapacitorController red />
      </div>
    </>
  );
};

export default StartUp;
