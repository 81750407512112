import React from "react";
import Layout from "../../components/Layout";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import formatDate from "../../composable/FormatDate";
import { useTranslation } from "react-i18next";
import TheLoader from "../../components/TheLoader";

const OrderHistory = () => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [firstLoad, setFirstLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  axios.defaults.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
  };

  // On firstLoad
  useEffect(() => {
    if (firstLoad === false) {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}shop/order`)
        .then((res) => {
          setOrders(res.data.shopOrders);
          setLoading(false);
        })
        .catch(() => {
          toast(t("error.loading-orders"), {
            type: "error",

            position: "top-center",
          });
          setLoading(false);
        });

      setFirstLoad(true);
    }
  }, []);

  return (
    <>
      <TheLoader isLoading={loading} />

      <Layout Title={t("general.previous-orders")}>
        <div className="grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2">
          {orders.map((order) => {
            return (
              <div key={order.id}>
                <div className="dark:glass relative h-full rounded-xl bg-white p-6 shadow-md">
                  <div className="mb-4 flex justify-between">
                    <div className="font-robotoslab font-bold text-primary dark:text-primary-light">
                      <strong>Order #{order.external_id}</strong>
                    </div>
                  </div>

                  {order.items.map((item) => {
                    return (
                      <div key={item.article.id}>
                        <Link to={`./${order.id}`} state={order}>
                          <div className="flex flex-col gap-y-3 text-sm">
                            <div className="flex justify-between">
                              <strong className="flex-1">{t("general.product")}: </strong>
                              <span className="flex-1 text-right">{item.article.name}</span>
                            </div>
                            <div className="flex justify-between">
                              <strong className="flex-1">{t("gift-shop.ordered")}: </strong>
                              <span className="flex-1 text-right">{formatDate(order.created_at)}</span>
                            </div>
                            <div className="flex justify-between">
                              <strong className="flex-1">{t("gift-shop.points")}: </strong>
                              <span className="flex-1 text-right">{item.points}</span>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>

        {!orders && !loading && <div className="mt-5 px-4 text-center">{t("gift-shop.no-orders-found")}</div>}
      </Layout>
    </>
  );
};

export default OrderHistory;
