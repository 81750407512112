import React from "react";
import { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import TheLoader from "../../components/TheLoader";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import formatDate from "../../composable/FormatDate";
import StatusBar from "../../components/StatusBar";

const DetailProductRegistration = () => {
  const state = useLocation();

  const [registration, setRegistration] = useState([]);
  const [firstLoad, setFirstLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  //set axios
  axios.defaults.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
  };

  // On firstLoad
  useEffect(() => {
    if (firstLoad === false) {
      if (state.state === undefined || state.state === null) {
        //State is not set
        setLoading(true);
        const id = window.location.pathname.split("/")[3];
        //TODO: Test data if API is made
        axios
          .get(`${process.env.REACT_APP_API_URL}registration/${id}`)
          .then((res) => {
            setRegistration(res.data.data);
            setLoading(false);
          })
          .catch(() => {
            toast(t("error.data-retrieval"), {
              type: "error",

              position: "top-center",
            });
            setLoading(false);
          });
        setLoading(false);
      } else {
        //State is set
        const stateData = state.state.registration;
        setRegistration(stateData);
      }

      setFirstLoad(true);
    }
  }, []);

  return (
    <Layout Title={t("action-registration.previous-registrations")}>
      {/* TheLoader */}
      <TheLoader isLoading={loading} />

      {registration.promotion !== undefined && (
        <div className="mt-8">
          <div className="relative mt-8">
            <StatusBar statuses={registration.statuses} />
            {/* TODO: Add track and trace URL */}
            {/*<a*/}
            {/*  href="/"*/}
            {/*  target="_blank"*/}
            {/*  rel="noopener noreferrer"*/}
            {/*  className="flex h-[46px] items-center justify-between rounded-xl bg-button-primary px-3 font-robotoslab font-bold shadow-md dark:bg-primary"*/}
            {/*>*/}
            {/*  <div className="flex items-center justify-start gap-2">*/}
            {/*    <div className="w-5">*/}
            {/*      <FontAwesomeIcon icon="fa-regular fa-box-alt" />*/}
            {/*    </div>*/}
            {/*    {t("action-registration.follow-shipment")}*/}
            {/*  </div>*/}
            {/*  <FontAwesomeIcon icon="fa-regular fa-arrow-up-right-from-square" />*/}
            {/*</a>*/}
          </div>

          <hr className="my-6 border-line" />

          <div className="mb-3 font-robotoslab font-bold text-primary dark:text-primary-light">
            <strong>{registration.promotion.name}</strong>
          </div>
          <div className="flex flex-col gap-y-1">
            <div className="flex justify-between">
              {/* TODO: created_at date  */}
              <strong>{t("general.registration")}: </strong>
              <span className="text-right">{formatDate(registration.purchase_date)}</span>
            </div>
            <div className="flex justify-between">
              {/* TODO: refernce */}
              <strong>{t("general.reference")}: </strong>
              <span>{registration.external_id}</span>
            </div>
            <div className="flex justify-between">
              <strong>{t("general.article")}: </strong> <span>{registration.article.name}</span>
            </div>
            <div className="flex justify-between">
              <strong>{t("general.date-of-purchase")}: </strong>
              <span>{formatDate(registration.purchase_date)}</span>
            </div>
            <div className="flex justify-between">
              <strong>{t("general.serialnumber")}: </strong>
              {registration.serial_numbers.map((serial) => {
                return (
                  <div className="flex items-center justify-between" key={serial.serialnumber}>
                    <span>{serial.serialnumber}</span>
                  </div>
                );
              })}
            </div>

            <div className="mt-5 flex justify-between">
              <p>
                <strong>{t("general.post-address")}:</strong>
              </p>
              <p className="text-right">
                <span>{`${registration.address.streetname} ${registration.address.housenumber}`}</span>
                <br />
                {`${registration.address.zipcode} ${registration.address.city}`}
              </p>
            </div>

            <hr className="my-6 border-line" />

            <div className="flex items-center gap-x-4">
              {registration.serial_numbers.map((serial) => {
                return (
                  <>
                    <a
                      className="dark:glass flex h-[46px] w-full items-center gap-4 rounded-xl bg-button-primary px-3 font-robotoslab font-bold text-primary shadow-md dark:text-primary-light"
                      href={`${serial.serialnumber_image}?token=${localStorage.getItem("userAuth")}`}
                      rel="noopener noreferrer nofollow"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon="fa-regular fa-arrow-down-to-line" />
                      {t("general.serialnumber")}
                    </a>
                  </>
                );
              })}
              <a
                className="dark:glass flex h-[46px] w-full items-center gap-4 rounded-xl bg-button-primary px-3 font-robotoslab font-bold text-primary shadow-md dark:text-primary-light"
                href={`${registration.purchase_image}?token=${localStorage.getItem("userAuth")}`}
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                <FontAwesomeIcon icon="fa-regular fa-arrow-down-to-line" />
                {t("general.invoice")}
              </a>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default DetailProductRegistration;
