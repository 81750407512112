import React from "react";
import Layout from "../../components/Layout";
import { useState, useEffect } from "react";
import TheLoader from "../../components/TheLoader";
import { Link } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDebouncedCallback } from "use-debounce";
import formatDate from "../../composable/FormatDate";

const HistoryProductRegistration = () => {
  const [search, setSearch] = useState("");
  const [registrations, SetRegistations] = useState([]);
  const [firstLoad, setFirstLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const debouncedSearch = useDebouncedCallback((value) => setSearch(value), 700);

  axios.defaults.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
  };

  // On firstLoad
  useEffect(() => {
    if (firstLoad === false) {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}registration`)
        .then((res) => {
          SetRegistations(res.data.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          throw new Error(err);
        });

      setFirstLoad(true);
    }
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}registration?search=` + search)
      .then((response) => {
        SetRegistations(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        toast.error(t("error.something-went-wrong"), {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
        });
        return error;
      });
  }, [search]);

  return (
    <>
      {/* Loader */}
      <TheLoader isLoading={loading} />

      <Layout Title={t("action-registration.previous-registrations")}>
        <div className="mt-8">
          <div className="grid grid-cols-4">
            <div className="relative col-span-full flex justify-between gap-4">
              <div className="relative w-full">
                <div className="absolute left-4 top-2">
                  <FontAwesomeIcon icon="fa-regular fa-search" />
                </div>
                <input
                  className="h-10 w-full rounded-full py-2 pl-11 pr-4 text-sm shadow-md dark:bg-secondary-dark"
                  placeholder={t("general.search")}
                  type="text"
                  id="search"
                  onChange={(e) => debouncedSearch(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2">
            {registrations.map((registration) => {
              if (registration.statuses.length > 0) {
                return (
                  <>
                    <Link to={`./${registration.id}`} state={{ registration: registration }}>
                      <div className="card dark:glass h-full rounded-xl bg-white p-6 shadow-md" key={registration.id}>
                        <div className="pb-3 font-robotoslab font-bold text-primary dark:text-primary-light">
                          <span>{registration.promotion.name}</span>
                        </div>
                        <div className="flex flex-col gap-y-1 text-sm">
                          <div className="flex justify-between">
                            <strong>{t("general.registration")}: </strong>
                            <span className="text-right">{formatDate(registration.purchase_date)}</span>
                          </div>
                          <div className="flex justify-between">
                            <strong>{t("general.article")}: </strong>
                            <span className="text-right">{registration.article.name}</span>
                          </div>
                          <div className="flex justify-between">
                            <strong>Status: </strong>
                            <span className="text-right">
                              {t(`status.${registration.statuses[0].name.toLowerCase()}`)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </>
                );
              }
              return null;
            })}
            {registrations.length <= 0 && !loading && (
              <div className="mt-5 text-center">{t("action-registration.no-registrations-found")}</div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default HistoryProductRegistration;
