import React from "react";
import Logo from "../../img/logos/Makita_Logo_Transparant.svg";
import iOS from "../../composable/ios";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LandingspageNavbar = ({ authenticated }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));

  return (
    <div className="polygon-wrapper z-50">
      <div className={`${iOS() && "pt-10"} polygon-landingspage grid grid-cols-12`}>
        <div className="default-cols container flex h-[104px] items-center justify-between">
          <img src={Logo} className="h-8" alt="logo" />
          {authenticated && (
            <div className="flex items-center gap-x-4">
              <Link to="../notifications" className="w-3 text-white">
                <FontAwesomeIcon icon="fa-regular fa-bell" />
              </Link>
              <Link to="../profiel">
                {!userData.avatar && (
                  <img
                    src="https://media.istockphoto.com/vectors/default-avatar-photo-placeholder-icon-grey-profile-picture-business-vector-id1327592449?k=20&m=1327592449&s=612x612&w=0&h=6yFQPGaxmMLgoEKibnVSRIEnnBgelAeIAf8FqpLBNww="
                    alt="profile-pic"
                    className="size-10 rounded-full bg-primary"
                  />
                )}
                {userData.avatar && (
                  <img
                    src={`${userData.avatar}?token=${localStorage.getItem("userAuth")}`}
                    alt="profile-pic"
                    className="size-10 rounded-full bg-primary"
                  />
                )}{" "}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LandingspageNavbar;
