import React, { useState, useEffect } from "react";
import axios from "axios";
import TheLoader from "../../components/TheLoader";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import iOS from "../../composable/ios";
import GetSession from "../../composable/GetSession";
import SetSession from "../../composable/SetSession";

// Custom components
import Layout from "../../components/Layout";
import NewsCardLarge from "../../components/cards/news/NewsCardLarge";
import InstagramCard from "../../components/cards/InstagramCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import setSession from "../../composable/SetSession";
import getSession from "../../composable/GetSession";

const Discover = () => {
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [feed, setFeed] = useState([]);
  const [newsFeed, setNewsFeed] = useState(false);
  const [upcomingFeed, setUpcomingFeed] = useState(false);
  const [instaFeed, setInstaFeed] = useState(false);
  const [feedType, setFeedType] = useState("wall");
  const { t } = useTranslation();

  //   Data fetching
  axios.defaults.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
  };

  function sortFeed(feedData) {
    let news = [];
    let upcoming = [];

    // eslint-disable-next-line array-callback-return
    feedData.map((feedItem) => {
      if (feedItem.tags.includes("tagA")) {
        news.push(feedItem);
      } else if (feedItem.tags.includes("tagB")) {
        upcoming.push(feedItem);
      }
    });

    setNewsFeed(news);
    setUpcomingFeed(upcoming);

    let data = {
      news: news,
      upcoming: upcoming,
    };

    SetSession("DiscoverItems", data);
  }

  // On first load
  useEffect(() => {
    if (firstLoad === false) {
      setLoading(true);

      if (GetSession("DiscoverItems") !== null) {
        let data = GetSession("DiscoverItems");
        setNewsFeed(data.news);
        setUpcomingFeed(data.upcoming);
        setFeed(GetSession("DiscoverFeed"));

        setLoading(false);
      } else {
        axios
          .get(`${process.env.REACT_APP_API_URL}discover`)
          .then((res) => {
            setFeed(res.data.data);
            SetSession("DiscoverFeed", res.data.data);
            setLoading(false);
            sortFeed(res.data.data);
          })
          .catch(() => {
            toast(t("error.data-retrieval"), {
              type: "error",

              position: "top-center",
            });
            setLoading(false);
          });
      }

      if (GetSession("InstagramPosts") !== null) {
        setInstaFeed(getSession("InstagramPosts"));
      } else {
        axios.get(`${process.env.REACT_APP_API_URL}instagram`).then((res) => {
          let instaFilteredFeed = res.data.filter((item) => item.type === "image");
          if (instaFilteredFeed.length) {
            setInstaFeed(instaFilteredFeed);
            setSession("InstagramPosts", instaFilteredFeed);
          }
        });
      }

      setFirstLoad(true);
      return undefined;
    }
  }, []);

  useEffect(() => {
    if (!getSession("InstagramPostsSet")) {
      setTimeout(() => {
        if (instaFeed.length) {
          const instaLength = instaFeed.length;
          let currentFeed = [...feed]; // Create a copy of feed to modify
          let index = 1; // Start inserting at index 1

          if (currentFeed.length > 0) {
            // Loop through instaFeed and insert elements at the specified indices
            for (let i = 0; i < instaLength; i++) {
              if (index < currentFeed.length) {
                currentFeed.splice(index, 0, instaFeed[i]); // Insert instaFeed[i] at index
                index += 4; // Move to the next index (1, 5, 10, 15, ...)
              } else {
                currentFeed.push(instaFeed[i]);
                break;
              }
            }
          } else {
            currentFeed = instaFeed;
          }

          setFeed(currentFeed);
          SetSession("DiscoverFeed", currentFeed);
          SetSession("InstagramPostsSet", true);
        }
      }, 400);
    }

    return undefined;
  }, [instaFeed]);

  return (
    <>
      {/* TheLoader */}
      <TheLoader isLoading={loading} />

      <div className={`fixed right-4 z-20 ${iOS() ? "bottom-24" : "bottom-20"}`}>
        <div className="flex gap-x-1">
          <div
            className={`animate cursor-pointer rounded-xl p-3 font-bold shadow-md ${feedType === "wall" ? "bg-primary text-white dark:bg-primary-light" : "dark:glass bg-button-primary hover:bg-primary-light dark:hover:bg-secondary"}`}
            onClick={() => setFeedType("wall")}
          >
            {t("discover.my-wall")}
          </div>
          <div
            className={`animate cursor-pointer rounded-xl p-3 font-bold shadow-md ${feedType === "nieuws" ? "bg-primary text-white dark:bg-primary-light" : "dark:glass bg-button-primary hover:bg-primary-light dark:hover:bg-secondary"}`}
            onClick={() => setFeedType("nieuws")}
          >
            {t("discover.news")}
          </div>
          <div
            className={`animate cursor-pointer rounded-xl p-3 font-bold shadow-md ${feedType === "binnenkort" ? "bg-primary text-white dark:bg-primary-light" : "dark:glass bg-button-primary hover:bg-primary-light dark:hover:bg-secondary"}`}
            onClick={() => setFeedType("binnenkort")}
          >
            {t("discover.coming-soon")}
          </div>
          <Link
            to="/voorkeur"
            className="animate dark:glass flex h-[46px] cursor-pointer items-center justify-between rounded-xl bg-button-primary px-3 shadow-md hover:bg-primary-light dark:hover:bg-secondary"
          >
            <FontAwesomeIcon icon="fa-regular fa-heart" />
          </Link>
        </div>
      </div>

      <Layout Title={t("discover.title")} HasTabs>
        <div className="mb-10 columns-1 gap-x-4 gap-y-6 md:columns-2">
          {feed.length > 0 &&
            (feedType === "wall"
              ? feed.map((feedItem, index) => (
                  <article key={index} className={index <= feed.length - 2 && "mb-6"}>
                    {feedItem.type !== undefined ? (
                      <InstagramCard post={feedItem} />
                    ) : (
                      // General items
                      <NewsCardLarge key={index} feedItem={feedItem} />
                    )}
                  </article>
                ))
              : // Nieuws
                feedType === "nieuws"
                ? newsFeed.map((feedItem, index) => (
                    <article key={index} className={index <= newsFeed.length - 2 && "mb-6"}>
                      <NewsCardLarge key={index} feedItem={feedItem} />
                    </article>
                  ))
                : // Binnenkort
                  feedType === "binnenkort" &&
                  upcomingFeed.map((feedItem, index) => (
                    <article key={index} className={index <= upcomingFeed.length - 2 && "mb-6"}>
                      <NewsCardLarge key={index} feedItem={feedItem} />
                    </article>
                  )))}
        </div>
      </Layout>
    </>
  );
};

export default Discover;
