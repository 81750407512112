import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useEffect } from "react";
import NotificationCard from "../components/cards/NotificationCard";
import { toast } from "react-toastify";
import Layout from "../components/Layout";

const Notificatons = () => {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  let unseenNotification = false;

  const [firstLoad, setFirstLoad] = useState(true);
  const [notifications, setNotifications] = useState([]);

  axios.defaults.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
  };

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      axios.get(`${process.env.REACT_APP_API_URL}notification`).then((response) => {
        if (response.data.data.length > 0) {
          unseenNotification = true;
          setNotifications(response.data.data);
        } else {
          unseenNotification = false;
          setNotifications([]);
        }
      });
    }
  }, []);

  function handleClick(event, id) {
    axios.delete(`${process.env.REACT_APP_API_URL}notification/${id}`).then((response) => {
      if (response.data.success) {
        let newNotifications = notifications.filter((notification) => notification.id !== id);
        setNotifications(newNotifications);
        if (newNotifications.length === 0) {
          unseenNotification = false;
        }
      } else {
        toast(t("error.notification"), {
          type: "error",

          position: "top-center",
        });
      }
    });
  }

  return (
    <>
      <Layout Title={t("general.notifications")}>
        <span className="font-robotoslab font-bold text-primary dark:text-primary-light">
          {t("general.notifications")}
        </span>

        {/* TODO: api inladen wanneer beschikbaar */}
        <div className="mt-4 flex flex-col gap-y-3">
          {notifications.length > 0 ? (
            notifications.map((notification) => {
              return (
                <NotificationCard
                  key={notification.id}
                  content={notification}
                  handleClick={(event) => handleClick(event, notification.id)}
                />
              );
            })
          ) : (
            <div className="mt-8 text-center">
              <span className="text-sm text-black dark:text-white">{t("general.no-notifications")}</span>
            </div>
          )}
        </div>
      </Layout>
    </>
  );
};

export default Notificatons;
