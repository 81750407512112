import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotificationCard = ({ content, handleClick }) => {
  const { t } = useTranslation();
  let type = "";
  let link = "";

  // TODO: test when api is ready
  if (content.notifiable_type === "App\\Models\\RegistrationOrder") {
    type = "registration";
    link = `/actieregistratie/registraties/${content.notifiable_id}`;
  } else if (content.notifiable_type === "App\\Models\\WarrantyOrder") {
    type = "warranty";
    link = `/garantieregistratie/registraties/${content.notifiable_id}`;
  } else if (content.notifiable_type === "App\\Models\\ShopOrder") {
    // eslint-disable-next-line no-unused-vars
    type = "shop";
    link = `/cadeauwinkel/bestellingen/${content.notifiable_id}`;
  }

  return (
    <Link to={`${link}`}>
      <div className="dark:glass rounded-xl bg-white p-3 shadow-md" onClick={handleClick}>
        <div className="mb-3 flex items-center justify-between">
          <h2 className="font-robotoslab font-bold text-primary dark:text-primary-light">{t(content.message)}</h2>
          <FontAwesomeIcon icon="fa-regular fa-arrow-right-long" className="text-primary dark:text-primary-light" />
        </div>
        <p className="mb-3 text-sm"></p>

        <div className="flex flex-col gap-y-1">
          <div className="flex justify-between">
            <p className="flex-1 text-sm font-bold">{t("general.product")}:</p>
            {/* todo: fix hardcoding */}
            <p className="flex-1 text-right text-sm">#201234567</p>
          </div>
          <div className="flex justify-between">
            <p className="flex-1 text-sm font-bold">{t("general.date-and-time")}:</p>
            {/* todo: fix hardcoding */}
            <p className="flex-1 text-right text-sm">02-04-2024, 09:41</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default NotificationCard;
