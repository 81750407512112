import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import iOS from "../../composable/ios";

const GuestNav = (page) => {
  const { t } = useTranslation();

  return (
    <nav className="sticky-nav z-[99] grid w-full grid-cols-12 bg-white px-4 dark:glass">
      <div className={`default-cols ${iOS() ? "pb-[29px] pt-3 lg:py-3" : "py-3"}`}>
        <div className="flex items-center justify-between lg:justify-around">
          <Link to={"/"}>
            <div
              className={`group flex size-12 items-center justify-center lg:w-full  ${page.page === undefined ? "nav-item-active" : "nav-item"}`}
            >
              {page.page === undefined ? (
                <FontAwesomeIcon icon="fa-solid fa-home" className="nav-icon" />
              ) : (
                <FontAwesomeIcon icon="fa-regular fa-home" className="nav-icon" />
              )}
            </div>
          </Link>
          <Link to={"/cadeauwinkel"}>
            <div
              className={`group flex size-12 items-center justify-center lg:w-full ${page.page === t("gift-shop.title") ? "nav-item-active" : "nav-item"}`}
            >
              {page.page === t("gift-shop.title") ? (
                <FontAwesomeIcon icon="fa-solid fa-shopping-bag" className="nav-icon" />
              ) : (
                <FontAwesomeIcon icon="fa-regular fa-shopping-bag" className="nav-icon" />
              )}
            </div>
          </Link>
          <Link to={"/login"}>
            <div className="nav-item-inactive flex size-12 cursor-not-allowed items-center justify-center lg:w-full">
              <FontAwesomeIcon icon="fa-regular fa-tags" className="nav-icon" />
            </div>
          </Link>
          <Link to={"/login"}>
            <div className="nav-item-inactive flex size-12 cursor-not-allowed items-center justify-center lg:w-full">
              <FontAwesomeIcon icon="fa-regular fa-briefcase" className="nav-icon" />
            </div>
          </Link>
          <Link to={"/more"}>
            <div
              className={`group flex size-12 items-center justify-center lg:w-full ${page.page === "more" ? "nav-item-active" : "nav-item"}`}
            >
              <FontAwesomeIcon icon="fa-regular fa-ellipsis" className="nav-icon" />
            </div>
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default GuestNav;
