import React from "react";

import { useTranslation } from "react-i18next";
import Button from "../Button";

const PointsCard = ({ userData }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="relative grid grid-cols-2 gap-x-4">
        <div className="dark:glass col-span-1 flex flex-col rounded-xl bg-white px-4 py-3 shadow-md">
          <span className="font-robotoslab text-[40px] font-bold">{userData.points}</span>
          <p className="font-robotoslab text-black dark:text-primary-light">{t("general.gift-points")}</p>
        </div>
        <div className="col-span-1 flex flex-col justify-between">
          <Button to="/cadeauwinkel" text={t("gift-shop.title")} primary arrow />
          <Button to="/profiel" text={t("general.profile")} arrow />
        </div>
      </div>
    </>
  );
};

export default PointsCard;
