import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Layout from "../../components/Layout";
import TheLoader from "../../components/TheLoader";

const DiscoverItem = () => {
  const location = useLocation();
  const data = location.state;
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);

    let description = data.description;
    // replace the src="..." with src="https://makita.appartdev.nl/..."
    description = description.replace(/src="/g, 'src="https://makita.nl');
    description = description.replace(
      /<h2/g,
      '<h2 className="text-primary dark:text-primary-light font-bold mb-2 text-xl" ',
    );
    description = description.replace(/<h3/g, '<h3 className="text-primary dark:text-primary-light font-bold mb-2" ');
    description = description.replace(/<h1/g, '<h1 className="text-primary dark:text-primary-light font-bold mb-2" ');

    setTimeout(() => {
      document.getElementById("descripton").innerHTML = description;
      setLoading(false);
    }, 150);

    return undefined;
  }, []);

  return (
    <>
      <TheLoader isLoading={loading} />
      <Layout Title={t("discover.news")}>
        <div className="discover-item grid grid-cols-12">
          <div className="col-span-12 lg:col-span-8 lg:col-start-3 xl:col-span-6 xl:col-start-4">
            <div className="overflow-hidden rounded-lg text-white shadow-md">
              <img className="h-auto w-full rounded-xl" src={data.image_url} alt={data.image_url} />
            </div>
            <h1 className="text-bold mt-10 text-xl text-primary dark:text-primary-light">{data.title}</h1>
            <div id="description" className="mt-6 leading-relaxed"></div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default DiscoverItem;
