import React from "react";
import Nav from "./navbar/Nav";
import TopNav from "./navbar/TopNav";
import GuestNav from "./navbar/GuestNav";
import TopNavGuest from "./navbar/TopNavGuest";
import CapacitorController from "./CapacitorController";
import iOS from "../composable/ios";
import { useEffect, useState } from "react";

const Layout = ({ Title, children }) => {
  const [guestAccount, setGuestAccount] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (firstLoad) {
      if (localStorage.getItem("userAuth")) {
        setGuestAccount(false);
      }
      setFirstLoad(false);
    }
  }, []);

  return (
    <>
      {!firstLoad &&
        (guestAccount ? (
          <>
            <GuestNav page={Title} />
            <TopNavGuest Title={Title} />
          </>
        ) : (
          <>
            <Nav page={Title} />
            <TopNav Title={Title} />
          </>
        ))}

      {!firstLoad && (
        <div className={`grid grid-cols-12 pb-28 pt-6 lg:pt-12 ${iOS() ? "mt-6 md:mt-24" : "mt-12"}`}>
          <div id="content" className="default-cols container mx-auto px-4 lg:px-0">
            {children}
          </div>
          <CapacitorController />
        </div>
      )}
    </>
  );
};

export default Layout;
