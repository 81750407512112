import React from "react";
import Products from "../../components/shop/Products";
import Layout from "../../components/Layout";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Shop = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const { t } = useTranslation();

  let guestAccount = false;
  if (!userData) {
    guestAccount = true;
  }

  return (
    <div>
      <Layout Title={t("gift-shop.title")}>
        {/* Top */}
        {!guestAccount && (
          <>
            <div className="mb-6 flex items-center justify-between">
              <span className="text-sm">
                <span className="font-bold">{userData.points}</span> {t("general.gift-points")}
              </span>

              <Link to="bestellingen" className="btn btn--primary">
                {t("general.previous-orders")}
                <FontAwesomeIcon icon="fa-regular fa-arrow-right-long" />
              </Link>
            </div>
          </>
        )}

        <Products guestAccount={guestAccount} />
      </Layout>
    </div>
  );
};

export default Shop;
